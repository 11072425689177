import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'SearchAsset',
  components: {
    DatePicker
  },
  watch: {
    currentPage: function() {
      this.getAllAssetsForReceiveBatch();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAllAssetsForReceiveBatch();
    }
  },
  props: ['batchData','batchStat','locAccessFlag','filedFlag'],
  data() {
    return {
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      setTimeVsetCode: null,
      vsetCode: null,
      manufacturer: null,
      serialNo: null,
      modelNo: null,
      purchaseDate: null,
      status: null,
      poNumber: null,
      description: null,
      value: null,
      batchStatus: null,
      assetNo: null,
      loader: false,
      recevingPeriod: null,
      type: null,
      showAdvSearchModal: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      checkAll: false,
      batchDetailsData: [],
      batchDetailsFields: [
        // {
        //   key: 'all',
        //   stickyColumn: true
        // },
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary',
          class:this.batchStat?"":'d-none'
        },
        {
          key:'asset_status',
          class:this.batchStat?"":'d-none'
        },
        {
          key: 'doc_num',
          label: 'Tag No.'
        },
        {
          key: 'cost',
          class: this.filedFlag? '' : 'd-none'
        },
        {
          key: 'description'
        },
        {
          key: 'gross_book_value',
          label: 'Value'
        },
        {
          key: 'batch_no'
        },
        {
          key: 'manufacturer_name',
          label: 'Manufacturer'
        },
        {
          key: 'model_no'
        },
        {
          key: 'serial_no'
        },
        {
          key: 'post_to_oracle_meaning',
          label: 'Post To Oracle'
        },
        {
          key: 'error_msg'
        },
        {
          key: 'type_of_defect',
          label:'Damage Type',
          class: this.batchData.transfer_type =='RMA'? '' : 'd-none'
        },
        {
          key:'trf_dtl_id',
          class:'d-none'
        }
      ],
      transferHdrId: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      batchNo: null,
      invoiceNumber: null,
      locationFrom: {
        text: null,
        value: null
      },
      locationTo: {
        text: null,
        value: null
      },
      trfdtlId:[],
      specIndex:null,
      transferType:null,
      editMode: false,
      selectedDamageAssetData:[],
      address1: null,
      address2: null,
      city: null,
      pin: null,
      country: null,
      state: null,
    };
  },
  mounted() {
    if (this.batchData) {
      this.transferType=this.batchData.transfer_type
      this.setAssetBatchId(this.batchData);
    }
    this.getAllAssetsForReceiveBatch();
  },
  methods: {
    setAssetBatchId(item) {
      this.transferHdrId = item.trf_batch_id;
      this.getHeadersForAssetBatch();
    },
    getAllAssetsForReceiveBatch() {
      const payload = {
        trfHdrId: this.transferHdrId,
        pagination: {
          _page: this.currentPage - 1,
          _limit: this.perPage
        }
      };
      this.$store
        .dispatch('assets/getAllAssetsForReceiveBatch', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data.page;
            result = result.map(asset => {
              if (typeof asset.type_of_defect === "string") {
                  asset.type_of_defect = JSON.parse(asset.type_of_defect);
              }
              return asset;
            });
            this.batchDetailsData = result.map(item => {
              item.all = false;
              return item;
            });
            this.totalRows = response.data.data.total_elements;
          }
        });
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.org_id,
          text: item.org_name
        };
        this.bookType = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.bookType = {
          value: item.org_id,
          text: item.org_name
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_DAMAGE_TYPE) {
        this.batchDetailsData[this.specIndex].type_of_defect.damage_type_dtl.push(item.value_code)
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideAdvSearchModal(flag) {
      this.showAdvSearchModal = flag;
    },
    getHeadersForAssetBatch() {
      this.loader = true;
      this.$store
        .dispatch('assets/getRecieveAssetBatchHdr', this.transferHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const data = response.data.data;
            this.batchNo = data.batch_num;
            this.invoiceNumber = data.invoice_number;
            this.locationFrom = {
              text: data.location_from,
              value: data.location_from_id
            };
            this.locationTo = {
              text: data.location_to,
              value: data.location_to_id
            };
            this.batchStatus = data.batch_status;
            this.transferType=data.transfer_type;
            this.address1=data.address_1;
            this.address2=data.address_2;
            this.city=data.city;
            this.country=data.country;
            this.state=data.state;
            this.pin=data.pin_code
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    checkUncheckAllFunc(e) {
      const isChecked = e;
      this.batchDetailsData.forEach(
        elem =>
          // (elem.all = elem.post_to_oracle === 'POSTED' ? false : isChecked)
          (elem.all = elem.post_to_oracle === 'POSTED' ? isChecked : false)
      );
    },
    postRecieveAsset() {
      // const filterAsset = this.batchDetailsData.filter(key=>{
      //   if(key.all){
      //     return key
      //   }
      // })
      // const receive = filterAsset.map(data => {
      //   return {
      //     asset_id: data.asset_id
      //   };
      // });
      const payload = {
        trx_id: this.transferHdrId
        // receive_in_oracle_dtl: receive ? receive : []
      };
      this.loader = true;
      this.$store
        .dispatch('assets/postRecieveAsset', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.getHeadersForAssetBatch();
            this.getAllAssetsForReceiveBatch();
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    postItRecieveAsset() {
      const payload = {
        trx_id: this.transferHdrId,
        partially_receive_in_oracle_dtl: [
        ]
      };
      this.trfdtlId.map(ele=>{
        payload.partially_receive_in_oracle_dtl.push({"trx_line_id": ele})
      })
      if(payload.partially_receive_in_oracle_dtl.length==0){
        this.$bvToast.toast("Kindly select an asset to receive", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }else if(this.filedFlag){
        const payload = {
          trx_id: this.transferHdrId,
          partially_receive_rgp: [
          ]
        };
        let countX=0;
       this.batchDetailsData.map(ele=>{
        if(ele.selectBox){
          payload.partially_receive_rgp.push({"trx_line_id": ele.trf_dtl_id,'cost':ele.cost})
          if(ele.cost==null){
            countX++
          }
        }
      })
      if(countX>0){
        this.$bvToast.toast("Kindly fill the mandatory field for the selected assets", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
        countX=0;
      }else{
        this.loader = true;
      this.$store
        .dispatch('assets/postItRgpRecieveAsset', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.getHeadersForAssetBatch();
            this.getAllAssetsForReceiveBatch();
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
      }
      }
      else{
      this.loader = true;
      this.$store
        .dispatch('assets/postItRecieveAsset', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.getHeadersForAssetBatch();
            this.getAllAssetsForReceiveBatch();
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
      }
    },
    // postAsset(){
    //   if(this.batchStat){
    //     this.postItRecieveAsset()
    //   }else{
    //     this.postRecieveAsset()
    //   }
    // },
    selectBoxChecked(flag,index,data,assetNum) {
      this.editMode=flag
      this.batchDetailsData[index].selectBox = flag;
      if(flag){
        this.trfdtlId.push(this.batchDetailsData[index].trf_dtl_id);
        this.selectedDamageAssetData.push(data);
      }
      else{
        this.trfdtlId=this.trfdtlId.filter(ele=>
          ele!==data.trf_dtl_id
        )
        this.selectedDamageAssetData = this.selectedDamageAssetData.filter(
          el => el.doc_num !== assetNum
        );
      }
    },
    removeTags(tags, tag) {
      const index = tags.indexOf(tag);
      if (index !== -1) {
        tags.splice(index, 1);
      }
    },
    updateDamage() {
      if (this.selectedDamageAssetData.length > 0) {
        var damageType=0
        const details = this.selectedDamageAssetData.map(data => {
          if(data.type_of_defect.damage_type_dtl.length==0){
              damageType++
          }else{
            if(this.batchData.transfer_type =='RMA'){
              return {
                asset_id: data.asset_id,
                description: data.description,
                hsn_sac_code: data.hsn_sac_code,
                damage_type: {
                  damage_type_dtl:data.type_of_defect.damage_type_dtl
                }
              };
            }
          }
        });
        const payload = {
          trfHdrId: this.transferHdrId,
          assetDetails: {
            asset_details: details
          }
        };
        if(damageType>0){
          this.$bvToast.toast('Damage Type can not be empty for assets', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }else{
            this.loader = true;
            this.$store
              .dispatch('assets/postDamageTypeReceivingEnd', payload)
              .then(response => {
                this.loader = false;
                if (response.status === 200) {
                  const results = response.data.message;
                  this.$bvToast.toast(results, {
                    title: 'Alert',
                    variant: 'success',
                    solid: true
                  });
                  this.selectedDamageAssetData = [];
                }
              })
              .catch(() => (this.loader = false));
        }
      } else {
        this.responseMsg = 'Please select assets to update';
        this.$bvToast.toast(this.responseMsg, {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    formatDecimal(amount,index){
      if (amount) {
        this.batchDetailsData[index].cost=parseFloat(amount).toFixed(2)
      }
    }
  }
};
