import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import User from '../../admin/user';
import batchDetails from '../receiveAsset/batchDetails';
import addEditTransferAsset from '../transferAsset/addEditTransferAsset';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'SearchAsset',
  components: {
    batchDetails,
    DatePicker,
    User,
    addEditTransferAsset
  },
  props:['batchStat','currentLoc','locAccessFlag'],
  watch: {
    currentPage: function () {
      this.getReceiveAssetBatch();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getReceiveAssetBatch();
    },
    selectAllCheckBox: function () {
      this.checkUncheckAll();
    }
  },
  data() {
    return {
      setTimeVsetCode: null,
      vsetCode: null,
      showBatchDetailsModal: false,
      showAddTransferAssetModal: false,
      transferDate: [],
      loader: false,
      invoiceNo: '',
      batchNo: '',
      description: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionAsset(),
      currentPage: 1,
      totalRows: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      legalEntity: {
        text: null,
        value: null
      },
      bookType: {
        text: null,
        value: null
      },
      fromLocation: {
        text: null,
        value: null
      },
      toLocation: {
        text: null,
        value: null
      },
      batchStatus: {
        text: null,
        value: null
      },
      showUserModal: false,
      invoice_num: null,
      receiveAssetData: [],
      receiveAssetFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary',
          class:this.batchStat=='IT'?"d-none":''
        },
        {
          key: 'batch_num',
          label: 'Batch No.'
        },
        {
          key: 'invoice_number',
          label: 'Invoice No.'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'book_type_code'
        },
        {
          key: 'location_from'
        },
        {
          key: 'location_to'
        },
        {
          key: 'transfer_date'
        },
        {
          key: 'received_date'
        },
        {
          key: 'batch_status_meaning',
          label: 'Status'
        },
        {
          key: 'period'
        },
        {
          key: 'from_gstn_no',
          label: 'GSTN From'
        },
        {
          key: 'to_gstn_no',
          label: 'GSTN To'
        },
        {
          key: 'created_by_name',
          label: 'Created By'
        }
      ],
      payload: null,
      batchData: null,
      createdBy:{
        text:null,
        value:null
      },
      creatorName: null,
      selectAllCheckBox: false,creatorId:null,
      toLoc:false,
      serialNum:null,
      filedFlag:false,
    };
  },
  mounted() {
    this.accessButtons = {
      deleteAllow: false,
      downloadAllow: false,
      uploadAllow: false,
      editAllow: false,
      addAllow: false,
      printAllow: false,
      approvalAllow: false
    };
    if(this.currentLoc){
      this.toLocation=this.currentLoc
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add' && !this.currentLoc) {
          this.showAddTransferAssetModal = true;
        }
      }
    });
  },
  methods: {
    postRequestReceiveAsset() {
      const filterData = this.receiveAssetData.filter(data => data.selectBox);
      if (filterData.length) {
        const receiveAsset = filterData.map(elem => {
          return {
            trx_id: elem.trf_batch_id
          };
        });
        const payload = {
          receive_in_oracle_dtl: receiveAsset ? receiveAsset : null
        };
        this.loader = true;
        this.$store
          .dispatch('assets/addBulkReceiveAsset', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Select Asset');
      }
    },
    showHideUserModal(flag) {
      this.showUserModal = flag;
    },
    selectedUser(item) {
      this.createdBy.text=item.name;
      this.createdBy.value=item.user_id;
      // this.creatorName = item.name;
      // this.creatorId = item.created_by;
      this.showUserModal = false;
    },
    getReceiveAssetBatch() {
      if(this.filedFlag){
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.legalEntity.value,
          book_type_code: this.bookType.value,
          loaction_from: this.fromLocation.value,
          batch_status: this.batchStatus.value,
          batch_type:this.batchStat?this.batchStat:"NON-IT",
          batch_num: this.batchNo,
          created_by: this.createdBy.value,
          transfer_date_from: commonHelper.formattedDate(this.transferDate[0]),
          transfer_date_to: commonHelper.formattedDate(this.transferDate[1]),
          serial_num:this.serialNum
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getRgpReceivedAssetsBatchList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
                this.receiveAssetData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => (this.loader = false));
      }else{
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.legalEntity.value,
          book_type_code: this.bookType.value,
          loaction_from: this.fromLocation.value,
          loaction_to: this.toLocation.value,
          batch_status: this.batchStatus.value,
          batch_type:this.batchStat?this.batchStat:"NON-IT",
          batch_num: this.batchNo,
          invoice_num: this.invoiceNo,
          created_by: this.createdBy.value,
          transfer_date_from: commonHelper.formattedDate(this.transferDate[0]),
          transfer_date_to: commonHelper.formattedDate(this.transferDate[1]),
          serial_num:this.serialNum
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getReceivedAssetsBatchList', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
                this.receiveAssetData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => (this.loader = false));
      }
    },
    openValueSetModal(vsetCode) {
      this.chooseParentVsetId(vsetCode);
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST:
          this.legalEntity = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          this.bookType = {
            value: null,
            text: null
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.bookType = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
          case appStrings.VALUESETTYPE.LOCATION:
            if(this.toLoc){
              this.toLocation = {
                value: item.value_set_dtl_id,
                text: item.value_code
              };
            }else{
              this.fromLocation = {
                    value: item.value_set_dtl_id,
                    text: item.value_code
                  };
            }
            break;
        case appStrings.VALUESETTYPE.ASSET_TRANSFER_BATCH_STATUS:
          this.batchStatus = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
      }
    },
    chooseValueset(name) {
      switch (name) {
        case 'legal_entity':
          this.openValueSetModal(appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST);
          break;
        case 'book_type':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_BOOK_TYPE);
          break;
        case 'fromLocation':
          this.openValueSetModal(appStrings.VALUESETTYPE.LOCATION);
          this.toLoc=false;
          break;
          case 'toLocation':
          this.openValueSetModal(appStrings.VALUESETTYPE.LOCATION);
          this.toLoc=true;
          break;
        case 'batch_status':
          this.openValueSetModal(
            appStrings.VALUESETTYPE.ASSET_TRANSFER_BATCH_STATUS
          );
          break;
      }
    },
    chooseParentVsetId(name) {
      switch (name) {
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.parent_value_set_id = this.legalEntity.value;
          break;
        default:
          this.parent_value_set_id = null;
      }
    },

    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    mainSearch() {
      this.getReceiveAssetBatch();
    },
    clear() {
      this.legalEntity = {
        text: null,
        value: null
      };
      this.bookType = {
        text: null,
        value: null
      };
      this.fromLocation = {
        text: null,
        value: null
      };
      // this.toLocation = {
      //   text: null,
      //   value: null
      // };
      this.batchStatus = {
        text: null,
        value: null
      };
      this.invoice_num = null;
      this.createdBy={
        value:null,text:null
      };
      this.batchNo = '';
      this.description = null;
      this.invoiceNo = '';
      this.transferDate = [];
      this.receiveAssetData = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.serialNum = null;
    },
    showHideTransferAssetModal(flag) {
      this.showAddTransferAssetModal = flag;
    },
    showHideBatchDetailsModal(flag, item) {
      this.showBatchDetailsModal = flag;
      this.batchData = item;
      if (!flag) {
        this.getReceiveAssetBatch();
      }
    },
    checkUncheckAll() {
      this.receiveAssetData = this.receiveAssetData.map(data => {
        data.selectBox = this.selectAllCheckBox;
        return data;
      });
    },
    selectBoxChecked(flag, index) {
      this.receiveAssetData[index].selectBox = flag;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.bookType.value) {
        this.bookType = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.fromLocation.value) {
        this.fromLocation = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.toLocation.value) {
        this.toLocation = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.batchStatus.value) {
        this.batchStatus = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.creatorName) {
        this.creatorName = null;
      }
    },
    toggleFunc(flag){
      if(flag){
        this.fromLocation=this.currentLoc;
        this.receiveAssetData = [];
        this.totalRows = null;
      }else{
        this.toLocation=this.currentLoc;
        this.fromLocation={
          text: null,
          value: null
        };
        this.receiveAssetData = [];
        this.totalRows = null;
      }
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
